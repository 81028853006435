import { DocumentTextIcon } from "@heroicons/react/24/outline";
import {
  Company,
  InterviewAgentTypesEnum,
  ProjectModesEnum,
  ProjectUpdate,
  ProjectWithInterviewCount,
  splitProjectQuestions,
} from "app-types";
import {
  Button,
  ButtonVariantsEnum,
  getInterviewLinkForProject,
  Label,
  SizesEnum,
} from "ui";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { ProjectSettingsQuestionsSection } from "../surveyQuestions/projectSettingsQuestionSection";
import { AIContextSetting } from "./aiContextSetting";
import { AppearanceSettingsSection } from "./appearanceSettingsSection";
import { InterviewLinkCopybox } from "./interviewLinkCopybox";
import { JobDescriptionSetting } from "./jobDescriptionSetting";
import { PhoneNumberDisplaySection } from "./phoneNumberDisplaySection";
import { ProjectNotificationsSettingsSection } from "./projectNotificationsSettingsSection";

interface VoiceModeProjectSettingsProps {
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (project: ProjectUpdate) => void;
  onClickSharingTemplates: () => void;
}

export const VoiceModeProjectSettings = ({
  project,
  onSaveProject,
  company,
  onClickSharingTemplates,
}: VoiceModeProjectSettingsProps) => {
  const dispatch = useAppDispatch();
  const { basicQuestions } = splitProjectQuestions(project.questions);

  const isRecruitingModeProject = Boolean(
    // Technically redundant check but keeping for clarity
    project.mode === ProjectModesEnum.VOICE_AGENT &&
      project.settings.interview_agent_type ===
        InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
  );

  return (
    <>
      <div className="flex flex-col space-y-6">
        <div>
          <Label size={SizesEnum.LARGE}>Share this AI interviewer</Label>
          <div className="flex flex-row space-x-4">
            <div className="flex-shrink-0">
              <Label size={SizesEnum.SMALL}>Phone number</Label>
              <PhoneNumberDisplaySection projectId={project.id} />
            </div>
            <div className="flex-shrink-0">
              <InterviewLinkCopybox
                inviteLinkUrl={getInterviewLinkForProject(
                  import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
                  company,
                  project
                )}
                project={project}
                onCopyInterviewLink={() => {
                  showNotification(dispatch, {
                    id: `project-${
                      project.id
                    }-link-copied-${new Date().getTime()}`,
                    primaryMessage: `Interview link copied to clipboard`,
                    type: NotificationTypeEnum.SUCCESS,
                  });
                }}
              />
            </div>
          </div>
          <div className="text-sm mb-2 text-slate-800">
            Add the phone number or interview link to your job post, or send it
            directly to candidates. We recommend phone interviews in most cases,
            and interview links in countries where candidates are less
            accustomed to phone calls.
          </div>
          <Button
            label="Sharing templates"
            icon={<DocumentTextIcon className="h-4 w-4 mr-2" />}
            variant={ButtonVariantsEnum.Secondary}
            onClick={onClickSharingTemplates}
          />
        </div>

        <div>
          <Label size={SizesEnum.LARGE}>Interview questions</Label>
          <div className="flex flex-col space-y-3">
            <div>
              <ProjectSettingsQuestionsSection
                company={company}
                project={project}
                complexQuestions={[]}
                basicQuestions={basicQuestions}
                onSaveProject={onSaveProject}
              />
            </div>

            <AIContextSetting
              context={project.settings.prompt_context}
              onSave={(newContext) => {
                onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    prompt_context: newContext || null,
                  },
                });
              }}
              isRecruitingMode={isRecruitingModeProject}
            />
            {isRecruitingModeProject && (
              <JobDescriptionSetting
                jobDescription={project.job_description}
                onSave={(newJobDescription) => {
                  onSaveProject({
                    id: project.id,
                    job_description: newJobDescription,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <ProjectNotificationsSettingsSection
        project={project}
        onSaveProject={onSaveProject}
      />
      <AppearanceSettingsSection
        onSaveProject={onSaveProject}
        company={company}
        project={project}
      />
    </>
  );
};
